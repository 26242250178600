import React from "react";

function Home() {

  const refreshPage = ()=>{
     window.location.reload();
  }

  return (
    <div style={{
      color: "#fff",
      backgroundColor: "blue",
      background: "blue",
      borderRadius: "10px",
      padding: "5px 0px 5px 0px",
      textAlign: "center",
      cursor: "pointer",
  }}>
      <a onClick={refreshPage}>選択した<br/>都市に移動</a>
    </div>
  );
}

export default Home